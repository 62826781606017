import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useLocation } from '@reach/router';
import BlockContent from '@sanity/block-content-to-react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import * as React from 'react';

import type { ISanityPostPreview } from '../types';
import { classNames } from '../utils/classnames';

const POSTS_PER_PAGE = 12;

interface PostPreviewsProps {
  posts: ISanityPostPreview[];
  totalCount: number;
  forHomePage?: boolean;
}

function PostPreviews({
  posts,
  totalCount,
  forHomePage = false,
}: PostPreviewsProps): JSX.Element {
  const pageCount = Math.ceil(totalCount / POSTS_PER_PAGE);
  const { search } = useLocation();
  const currentPage = Number(new URLSearchParams(search).get('page') ?? 1);
  const index = currentPage - 1;
  const from = index * POSTS_PER_PAGE;
  const to = from + POSTS_PER_PAGE;
  return (
    <div className="relative py-20 bg-light md:px-6 lg:py-24 lg:px-8">
      <div className="relative mx-auto max-w-screen-2xl">
        <h2 className="font-semibold text-center text-tertiary">
          <span className="inline-block text-4xl uppercase">
            Keep up with our Blogs
          </span>
        </h2>
        <div className="grid gap-16 mx-auto mt-12">
          {posts.slice(from, to).map((post, index) => (
            <div key={post.title} className="grid grid-cols-5 overflow-hidden">
              <div
                className={`flex flex-shrink-0 w-full h-full  md:col-span-2 opacity-0  md:opacity-100 ${
                  (index + 1) % 2
                    ? 'clip-polygon-left'
                    : 'clip-polygon-right order-last'
                }`}
              >
                <Link
                  aria-hidden
                  tabIndex={-1}
                  to={`/blog/${post.slug.current}/`}
                  className="flex-1 block"
                >
                  {post.mainImage ? (
                    <GatsbyImage
                      image={post.mainImage.asset.gatsbyImageData}
                      alt={post.mainImage.alt || ''}
                      className="h-full"
                    />
                  ) : null}
                </Link>
              </div>
              <div
                className={`flex flex-col justify-between flex-1 col-span-4 md:col-span-3 p-6 text-light ${
                  (index + 1) % 2 ? 'bg-tertiary' : 'bg-secondary'
                }`}
              >
                <div className="flex-1">
                  <Link
                    to={`/blog/${post.slug.current}/`}
                    className="block mt-2"
                  >
                    <p className="text-2xl font-semibold uppercase">
                      {post.title}
                    </p>
                    <BlockContent
                      renderContainerOnSingleChild
                      blocks={post._rawExcerpt}
                      className="mt-3 prose-lg"
                    />
                  </Link>
                </div>
                <div className="mt-4">
                  <p className="font-semibold">
                    <time dateTime={post.publishedAtISO} className="mr-2">
                      {moment(post.publishedAt).format('MMMM Do')}
                    </time>
                    |<span className="ml-2">{post.author}</span>
                  </p>
                </div>
                <p className="mt-6 text-sm font-medium">
                  <Link
                    aria-hidden
                    tabIndex={-1}
                    to={`/blog/${post.slug.current}/`}
                    className="uppercase hover:underline"
                  >
                    Read more
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </div>
        {forHomePage ? (
          <div className="flex justify-center mt-24">
            <Link
              to="/blog"
              className="inline-flex px-4 py-1 text-sm font-semibold tracking-wider uppercase border-2 transform-gpu skew-x-25 text-light bg-secondary border-secondary"
            >
              <span className="transform-gpu -skew-x-25">See more blogs</span>
            </Link>
          </div>
        ) : (
          <Pagination currentPage={currentPage} pageCount={pageCount} />
        )}
      </div>
    </div>
  );
}

interface PaginationProps {
  currentPage: number;
  pageCount: number;
}

function Pagination({
  currentPage,
  pageCount,
}: PaginationProps): JSX.Element | null {
  if (pageCount <= 1) return null;
  return (
    <nav className="flex items-center justify-center px-4 mt-12 sm:px-0">
      <div className="flex justify-end flex-1 w-0 -mt-px">
        <Link
          aria-disabled={currentPage <= 1}
          tabIndex={currentPage <= 1 ? -1 : undefined}
          to={
            currentPage > 1 ? `/blog/?page=${currentPage - 1}` : `/blog/?page=1`
          }
          className={classNames(
            currentPage <= 1 && 'pointer-events-none',
            'inline-flex items-center pt-4 pb-2 pr-1 text-sm font-medium border-t-2 border-transparent text-tertiary hover:text-secondary hover:border-secondary'
          )}
        >
          <ChevronLeftIcon className="w-5 h-5 text-tertiary" aria-hidden />
          <span className="sr-only">Previous</span>
        </Link>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {Array.from({ length: pageCount }, (_, index: number) => {
          const page = index + 1;
          return (
            <Link
              key={page}
              to={`/blog/?page=${page}`}
              className={classNames(
                currentPage === page
                  ? 'border-tertiary text-tertiary'
                  : 'border-transparent hover:text-secondary hover:border-secondary',
                'inline-flex items-center px-4 pt-4 pb-2 text-sm font-medium border-t-2'
              )}
            >
              {page}
            </Link>
          );
        })}
      </div>
      <div className="flex justify-start flex-1 w-0 -mt-px">
        <Link
          aria-disabled={currentPage >= pageCount}
          tabIndex={currentPage >= pageCount ? -1 : undefined}
          to={
            currentPage < pageCount
              ? `/blog/?page=${currentPage + 1}`
              : `/blog/?page=${pageCount}`
          }
          className={classNames(
            currentPage >= pageCount && 'pointer-events-none',
            'inline-flex items-center pt-4 pb-2 pl-1 text-sm font-medium text-tertiary border-t-2 border-transparent hover:text-secondary hover:border-secondary'
          )}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="w-5 h-5 text-tertiary" aria-hidden />
        </Link>
      </div>
    </nav>
  );
}

export { PostPreviews };
