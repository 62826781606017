import type { HLocation } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';

import { Layout } from '../../components/layout';
import { PostPreviews } from '../../components/post-previews';
import { SanityModules } from '../../components/sanity-modules';
import { SEO } from '../../components/seo';
import { ISanityHero } from '../../fragments/sanity-hero-parts';
import type { ISanityPostPreview, ISeo } from '../../types';

interface BlogPageTemplateProps {
  data: {
    allSanityPost: {
      nodes: ISanityPostPreview[];
      totalCount: number;
    };
    mostRecentPost: {
      nodes: ISanityPostPreview[];
    };
    sanityBlogPage: {
      hero: ISanityHero;
      seo: ISeo;
      contactForm: ISanityContactForm;
    };
  };
  location: HLocation;
}

function BlogPageTemplate({
  data,
  location,
}: BlogPageTemplateProps): JSX.Element {
  const { hero, seo, contactForm } = data.sanityBlogPage;

  return (
    <>
      <SEO
        title={seo?.metaTitle}
        image={seo?.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <SanityModules modules={[hero]} />
        <PostPreviews
          posts={data.allSanityPost.nodes}
          totalCount={data.allSanityPost.totalCount - 1}
        />
        <SanityModules modules={[contactForm]} />
      </Layout>
    </>
  );
}

export const query = graphql`
  query BlogPageQuery {
    sanityBlogPage(_id: { eq: "blogPage" }) {
      hero {
        ...SanityHeroParts
      }
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
      contactForm {
        ...SanityContactFormParts
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      ...SanityPostParts
      totalCount
    }
  }
`;

export default BlogPageTemplate;
